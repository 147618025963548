
import { apisetMealLogLists } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import { RequestPaging } from '@/utils/util'
import { dataTable, selectTable } from '@/api/dev'

@Component({
  components: {
    lsPagination,
    ExportData,
    DatePicker
  }
})
export default class ShopList extends Vue {
  /** S Data **/

  // apisetMealLogLists = apisetMealLogLists;

  pager: RequestPaging = new RequestPaging();

  selectData = []

  recordData = []
  searchObj: any = {
    id: '',
    name: '',
    start_time: '',
    end_time: ''
  };
  /** E Data **/

  /** S Methods **/

  // 获取商城列表
  getLists(): void {
    this.pager.request({
      callback: dataTable,
      params: {
        ...this.searchObj,
      },
    });
  }

  // async getLists() {
  //   this.recordData = await this.pager.request({
  //     callback: dataTable,
  //     params: {
  //       ...this.searchObj,
  //       id: this.$route.query.id,
  //     }
  //   })

  //   console.log(this.recordData)
  // }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      if (key === 'id') return
      this.$set(this.searchObj, key, '')
    })
    this.getLists()
  }

  handleSelectionChange = (val: any[]) => {
    let res = val.map(({ name, comment }) => ({
      name,
      comment
    }))

    this.$emit('input', res)

  }


  /** E Methods **/

  /** S ods **/
  created() {
    this.searchObj.id = this.$route.query.id
    this.getLists()
  }
}
