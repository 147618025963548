
import {
  apiSetMealLists,
  apiSetMealSwitchStatus,
  apiSetMealDelete,
} from "@/api/shop";
import {
  generateCode,
  generateDelete,
  generatePreview,
  generateTable,
  syncColumn,
  selectTable
} from '@/api/dev'
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import DatePicker from "@/components/date-picker.vue";
import dataTable from "../components/data-table.vue";
import codePreview from "../components/code-preview.vue";
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    DatePicker,
    dataTable,
    codePreview
  },
})
export default class SetMealList extends Vue {
  $refs!: { codepreviewdialog: any }

  /** S Data **/

  // apiLists = generateTable;
  code = []
  selectTable = []
  pager: RequestPaging = new RequestPaging();

  searchObj: Object = {
    status: "",
    shop_info: "",
    start_time: "",
    end_time: "",
  };
  /** E Data **/

  /** S Methods **/

  // 获取商城列表
  getLists(): void {
    this.pager.request({
      callback: generateTable,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 生成代码
  async handleGenerate(row: any): Promise<void> {
    await generateCode({ id: row.id });
    this.getLists();
  }

  // 同步
  async handleSync(row: any): Promise<void> {
    await syncColumn({ id: row.id });
    this.getLists();
  }

  // 删除商城
  async handleDelete(row: any): Promise<void> {
    await generateDelete({ id: row.id });
    this.getLists();
  }

  // 预览
  async handlePreview(id: number) {
    const data: any = await generatePreview({ id: id })
    // console.log("data", data)
    this.code = data

    this.$refs.codepreviewdialog?.onTrigger()
    // this.$refs.codepreviewdialog.close()
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getLists();
  }

  // 去编辑商城
  toEdit(id: number | any): void {
    this.$router.push({
      path: "/dev/edit",
      query: {
        id: id,
      },
    });
  }

  handdleSelectData(list: any) {
    console.log("handdleSelectData", list)
    this.selectTable = list
  }

  onConfirm() {
    console.log("onConfirm",)

    if (this.selectTable.length == 0) {
      this.$message.warning("请选择数据表")
      return
    }

    selectTable({
      table: this.selectTable
    }).then(res => {
      this.getLists()
    })



  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getLists();
  }
}
